import styled from "styled-components"

const BlogPostStyle = styled.article`
  .post-header {
    margin: 0 0 1.5rem 0;
  }

  .post-content {
    aside {
      &.pull {
        margin: 2rem 0 2rem 1rem;

        div,
        p {
          font-size: 1.25rem;
          color: var(--grey-1-color);
        }

        div.source::before {
          content: "—";
          margin: 0 0.5rem 0 0;
        }

        p {
          margin: 0 0 0.5rem 0;
        }
      }
    }

    blockquote {
      margin: 2rem 0 2rem -1rem;
      padding-left: 1rem;
      box-shadow: inset 3px 0 0 0 var(--primary-color);

      div,
      p {
        font-style: italic;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }

    ol {
      margin: 0 0 1rem 19px;
    }

    p:not(:last-child) {
      margin: 0 0 1rem 0;
    }
  }
`

export default BlogPostStyle
