import styled from "styled-components"

import devices from "../../helpers/devices"

export const H1 = styled.h1`
  font-size: 2.5rem;

  @media ${devices.desktop} {
    font-size: 3rem;
  }
`

export const H2 = styled.h2`
  font-size: 2rem;
  font-weight: 600;

  @media ${devices.desktop} {
    font-size: 2.5rem;
  }
`

export const H3 = styled.h3`
  font-size: 1.5rem;

  @media ${devices.desktop} {
    font-size: 2rem;
  }
`

export const H4 = styled.h4`
  font-size: 1.125rem;

  @media ${devices.desktop} {
    font-size: 1.5rem;
  }
`

export const HeroP = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;

  @media ${devices.tablet} {
    margin-bottom: 2rem;
  }
`
